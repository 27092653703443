.win {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 10000;
  display: none;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.win:before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.47);
}

.winContainer {
  text-align: center;
  padding: 0;
  margin-top: 0;
  display: table-cell;
  width: 100vw;
  height: 100vh;
  vertical-align: middle;
  position: relative;
}

.winContent {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  position: relative;
  text-align: left;
  display: inline-block;
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.1));
  max-width: 700px;
  min-width: 320px;
  margin: 20px 20px;
}

.winContentFulltext {
  padding: 50px;
  overflow: auto;
}

.winContentFulltext h3,
.winContentFulltext .h3 {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 25px 0;
  letter-spacing: -0.03em;
}

.winContent:after {
  display: block;
  width: 100%;
  content: ' ';
  clear: both;
}

.winContent .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
  line-height: 25px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.6;
}

.close:hover {
  opacity: 1;
}

@media (max-width: 1279px) {
  .winContent {
    max-width: 600px;
  }

  .winContentFulltext {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .winContent {
    margin: 10px 15px;
  }

  .winContentFulltext {
    padding: 20px 20px 30px 20px;
  }
}

.loadPhoto {
  text-align: center;
}

.colorBlue {
  color: #0a4475 !important;
}

.loadPhotoInputWrapper {
  position: relative;
  cursor: pointer;
}

.loadPhotoInputWrapper span {
  display: block;
  position: relative;
  z-index: 1;
}

.loadPhotoInputWrapper input {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.profileAvatar {
  width: 120px;
  height: 120px;
  border-radius: 150px;
  border: 1px solid #dddddd;
  margin: 0 auto;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
}

.small,
small {
  font-size: 16px;
}

@media (max-width: 1023px) {
  body {
    font-size: 16px;
  }

  .font-big {
    font-size: 20px;
  }

  .small,
  small {
    font-size: 14px;
  }

  h3,
  .h3 {
    font-size: 26px;
    margin: 0 0 25px 0;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btn-small {
    font-size: 14px !important;
    padding: 8px 13px !important;
  }
}

/* INPUT */

.input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  user-select: none;
  z-index: 5;
  padding-right: 50px;
}

.label {
  position: relative;
  padding: 0 0 0 30px;
  margin-right: 0px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  z-index: 3;
}

.input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 1px solid #cdd1da;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 0;
}

.input[type='checkbox']:checked + label:before {
  background-color: #b60e27;
  border-color: #b60e27;
}

.input[type='checkbox'] + label:after {
  content: ' ';
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IGZpbGw9IiNCNjBFMjciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIvPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTEyLjYzLDUuNzA5YzAuMDc3LTAuMDg4LDAuMTM2LTAuMTkxLDAuMTczLTAuMzAzYzAuMDM3LTAuMTEyLDAuMDUxLTAuMjI5LDAuMDQyLTAuMzQ2DQoJcy0wLjA0Mi0wLjIzMS0wLjA5Ni0wLjMzNmMtMC4wNTQtMC4xMDQtMC4xMjgtMC4xOTctMC4yMTktMC4yNzJjLTAuMDktMC4wNzUtMC4xOTUtMC4xMzItMC4zMDctMC4xNjZzLTAuMjMtMC4wNDYtMC4zNDctMC4wMzQNCgljLTAuMTE3LDAuMDEyLTAuMjMsMC4wNDctMC4zMzMsMC4xMDRjLTAuMTAzLDAuMDU2LTAuMTk0LDAuMTMzLTAuMjY3LDAuMjI1TDcuNDg4LDkuMTI3bC0xLjk2LTEuOTYxDQoJYy0wLjE2Ni0wLjE2LTAuMzg5LTAuMjQ5LTAuNjItMC4yNDdDNC42NzcsNi45Miw0LjQ1Niw3LjAxMyw0LjI5Myw3LjE3NkM0LjEyOSw3LjM0LDQuMDM3LDcuNTYsNC4wMzUsNy43OTENCgljLTAuMDAyLDAuMjMxLDAuMDg3LDAuNDU0LDAuMjQ3LDAuNjJsMi42NDMsMi42NDNjMC4wODcsMC4wODcsMC4xOSwwLjE1NCwwLjMwNCwwLjE5OHMwLjIzNiwwLjA2NCwwLjM1OCwwLjA1OQ0KCWMwLjEyMi0wLjAwNiwwLjI0Mi0wLjAzNiwwLjM1Mi0wLjA5MWMwLjExLTAuMDU0LDAuMjA3LTAuMTMxLDAuMjg1LTAuMjI1TDEyLjYzLDUuNzA5eiIvPg0KPC9zdmc+DQo=');
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 2px;
  opacity: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:disabled:before {
  opacity: 0.5;
  content: none;
  cursor: not-allowed;
}

.btn:disabled .icon {
  display: none;
  cursor: not-allowed;
}

.btn:disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btn .icon {
  width: 18px;
  height: 18px;
  line-height: 1;
  margin-right: 8px;
  margin-top: -2px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.iconCheckWhite {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NiA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi45OTkzIDEuOTE2NzVDMTEuMzU1NiAxLjkxNjc1IDEuOTE2MDIgMTEuMzU2MyAxLjkxNjAyIDIzLjAwMDFDMS45MTYwMiAzNC42NDM4IDExLjM1NTYgNDQuMDgzNCAyMi45OTkzIDQ0LjA4MzRDMzQuNjQzMSA0NC4wODM0IDQ0LjA4MjcgMzQuNjQzOCA0NC4wODI3IDIzLjAwMDFDNDQuMDgyNyAxMS4zNTYzIDM0LjY0MzEgMS45MTY3NSAyMi45OTkzIDEuOTE2NzVaTTMyLjEzOCAxOS40MzUxQzMyLjMwNjMgMTkuMjQyNyAzMi40MzQ0IDE5LjAxODcgMzIuNTE0OCAxOC43NzYxQzMyLjU5NTIgMTguNTMzNSAzMi42MjYzIDE4LjI3NzIgMzIuNjA2MiAxOC4wMjI1QzMyLjU4NjEgMTcuNzY3NyAzMi41MTUzIDE3LjUxOTUgMzIuMzk3OSAxNy4yOTI1QzMyLjI4MDQgMTcuMDY1NSAzMi4xMTg4IDE2Ljg2NDMgMzEuOTIyNSAxNi43MDA3QzMxLjcyNjEgMTYuNTM3IDMxLjQ5OTEgMTYuNDE0MyAzMS4yNTQ2IDE2LjMzOThDMzEuMDEwMiAxNi4yNjUyIDMwLjc1MzMgMTYuMjQwMyAzMC40OTkgMTYuMjY2NUMzMC4yNDQ4IDE2LjI5MjcgMjkuOTk4NCAxNi4zNjk1IDI5Ljc3NDMgMTYuNDkyM0MyOS41NTAyIDE2LjYxNTEgMjkuMzUyOSAxNi43ODE1IDI5LjE5NCAxNi45ODE3TDIwLjk1MjMgMjYuODY5OEwxNi42ODc4IDIyLjYwMzNDMTYuMzI2MyAyMi4yNTQyIDE1Ljg0MjEgMjIuMDYxIDE1LjMzOTYgMjIuMDY1NEMxNC44MzcgMjIuMDY5NyAxNC4zNTYzIDIyLjI3MTMgMTQuMDAwOSAyMi42MjY3QzEzLjY0NTYgMjIuOTgyIDEzLjQ0NCAyMy40NjI4IDEzLjQzOTYgMjMuOTY1M0MxMy40MzUzIDI0LjQ2NzkgMTMuNjI4NSAyNC45NTIgMTMuOTc3NiAyNS4zMTM1TDE5LjcyNzYgMzEuMDYzNUMxOS45MTU5IDMxLjI1MTcgMjAuMTQxNCAzMS4zOTg2IDIwLjM4OTYgMzEuNDk0OUMyMC42Mzc5IDMxLjU5MTEgMjAuOTAzNSAzMS42MzQ2IDIxLjE2OTQgMzEuNjIyNUMyMS40MzU0IDMxLjYxMDQgMjEuNjk2IDMxLjU0MyAyMS45MzQ1IDMxLjQyNDdDMjIuMTczIDMxLjMwNjMgMjIuMzg0MiAzMS4xMzk2IDIyLjU1NDcgMzAuOTM1MUwzMi4xMzggMTkuNDM1MVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
}

.btnSmall {
  font-size: 16px !important;
  padding: 8px 15px !important;
  text-transform: none;
  margin: 0;
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

select {
  font-size: inherit;
}

input[type='text'],
input[type='email'],
input[type='phone'],
input[type='date'],
input[type='password'],
input[type='number'],
select,
textarea {
  font-family: 'Manrope', sans-serif;
  font-size: inherit;
  line-height: 1;
  height: 39px;
  padding: 6px 10px 6px 10px;
  color: #012140;
  background-color: #fff;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  border-radius: 5px;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='phone']::focus,
input[type='date']::focus,
input[type='password']::focus,
input[type='number']:focus,
select:focus,
textarea:focus,
input[type='text']:active,
input[type='email']:active,
input[type='phone']:active,
input[type='date']:active,
input[type='password']:active,
input[type='number']:active,
select:active,
textarea:active {
  border-color: #012140;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='phone']::focus,
input[type='date']::focus,
input[type='password']::focus,
input[type='number']:focus,
select:focus,
textarea:focus,
input[type='text']:active,
input[type='email']:active,
input[type='phone']:active,
input[type='date']:active,
input[type='password']:active,
input[type='number']:active,
select:active,
textarea:active {
  border-color: #012140;
}

@media (max-width: 767px) {
  .blockWhite {
    padding: 20px 20px;
  }

  .formLine {
    margin: 0 0 15px 0;
  }
}

@media (max-width: 575px) {
  .btn {
    width: 100%;
  }
}
