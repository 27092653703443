html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
b,
strong {
  font-weight: 800;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  height: 100vh;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.3;
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: #012140;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #0a4475;
  font-family: 'Manrope', sans-serif;
  font-size: 18px !important;
  text-decoration: none !important;
  cursor: pointer;
}

h2,
.h2 {
  font-size: 44px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 30px 0;
  letter-spacing: -0.03em;
}

input[type='text'],
input[type='email'],
input[type='phone'],
input[type='date'],
input[type='time'],
input[type='password'],
input[type='number'],
select,
textarea {
  font-family: 'Manrope', sans-serif;
  font-size: inherit;
  line-height: 1;
  height: 39px;
  padding: 6px 10px 6px 10px;
  color: #012140;
  background-color: #fff;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  border-radius: 5px;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='phone']::focus,
input[type='date']::focus,
input[type='password']::focus,
input[type='number']:focus,
select:focus,
textarea:focus,
input[type='text']:active,
input[type='email']:active,
input[type='phone']:active,
input[type='date']:active,
input[type='time']:active,
input[type='time']:focus,
input[type='password']:active,
input[type='number']:active,
select:active,
textarea:active {
  border-color: #012140;
}

input[type='date'] {
  position: relative;
  color: rgba(255, 255, 255, 0);
}
input[type='date']::-webkit-calendar-picker-indicator {
  bottom: 0;
  background-position: calc(100% - 8px) 47%;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  opacity: 0.4;
}
input[type='date']:focus::-webkit-calendar-picker-indicator
  input[type='date']:active::-webkit-calendar-picker-indicator,
input[type='date'].has-value::-webkit-calendar-picker-indicator {
  opacity: 1;
}

input[type='date']:not(.has-value)::before {
  color: #0121406b;
  content: attr(placeholder);
}
input[type='date'].has-value,
input[type='date']:focus {
  color: #012140;
}
input[type='date']:focus::before {
  content: '' !important;
}

input[type='radio'],
input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  user-select: none;
}
label {
  position: relative;
  padding: 0 0 0 30px;
  margin-right: 0px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 1px solid #cdd1da;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 0;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  background-color: #b60e27;
  border-color: #b60e27;
}

input[type='checkbox'] + label:after,
input[type='radio'] + label:after {
  content: ' ';
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IGZpbGw9IiNCNjBFMjciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIvPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTEyLjYzLDUuNzA5YzAuMDc3LTAuMDg4LDAuMTM2LTAuMTkxLDAuMTczLTAuMzAzYzAuMDM3LTAuMTEyLDAuMDUxLTAuMjI5LDAuMDQyLTAuMzQ2DQoJcy0wLjA0Mi0wLjIzMS0wLjA5Ni0wLjMzNmMtMC4wNTQtMC4xMDQtMC4xMjgtMC4xOTctMC4yMTktMC4yNzJjLTAuMDktMC4wNzUtMC4xOTUtMC4xMzItMC4zMDctMC4xNjZzLTAuMjMtMC4wNDYtMC4zNDctMC4wMzQNCgljLTAuMTE3LDAuMDEyLTAuMjMsMC4wNDctMC4zMzMsMC4xMDRjLTAuMTAzLDAuMDU2LTAuMTk0LDAuMTMzLTAuMjY3LDAuMjI1TDcuNDg4LDkuMTI3bC0xLjk2LTEuOTYxDQoJYy0wLjE2Ni0wLjE2LTAuMzg5LTAuMjQ5LTAuNjItMC4yNDdDNC42NzcsNi45Miw0LjQ1Niw3LjAxMyw0LjI5Myw3LjE3NkM0LjEyOSw3LjM0LDQuMDM3LDcuNTYsNC4wMzUsNy43OTENCgljLTAuMDAyLDAuMjMxLDAuMDg3LDAuNDU0LDAuMjQ3LDAuNjJsMi42NDMsMi42NDNjMC4wODcsMC4wODcsMC4xOSwwLjE1NCwwLjMwNCwwLjE5OHMwLjIzNiwwLjA2NCwwLjM1OCwwLjA1OQ0KCWMwLjEyMi0wLjAwNiwwLjI0Mi0wLjAzNiwwLjM1Mi0wLjA5MWMwLjExLTAuMDU0LDAuMjA3LTAuMTMxLDAuMjg1LTAuMjI1TDEyLjYzLDUuNzA5eiIvPg0KPC9zdmc+DQo=');
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 2px;
  opacity: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.small input[type='checkbox'] + label:after,
.small input[type='radio'] + label:after,
.btn-small input[type='checkbox'] + label:after,
.btn-small input[type='radio'] + label:after,
.small input[type='checkbox'] + label:before,
.small input[type='radio'] + label:before,
.btn-small input[type='checkbox'] + label:before,
.btn-small input[type='radio'] + label:before {
  top: 0px;
}

input[type='checkbox']:checked + label:after,
input[type='radio']:checked + label:after {
  opacity: 1 !important;
}

.noScroll {
  overflow: hidden;
}
