.block {
  position: relative;
  z-index: 1;
  padding: 120px 0 120px 0;
  text-align: center;
}
.block .container {
  z-index: 10;
  position: relative;
}

.b {
  font-weight: 800;
}

.block.block1 {
  overflow: hidden;
  padding: 10vh 0 !important;
}

.blockBlue {
  position: relative;
  overflow: hidden;
  color: #fff;
  z-index: 1;
  padding: 120px;
  text-align: left;
  margin: 0 -120px 0 -120px;
}
.blockBlue .block2-img {
  position: absolute;
  bottom: 0;
  right: 15%;
  max-width: 396px;
  width: 35vw;
}
.blockBlue > * {
  color: #fff;
  z-index: 5;
  position: relative;
}

.bgMonoh {
  background-size: cover;
  background-blend-mode: luminosity;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.bgMonoh:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  filter: opacity(80%);
  z-index: 1;
}
.bgBlue {
  background-color: #0a4475;
  color: #fff;
  height: 90vh;
}
.bgBlue ul li:before {
  background-color: #fff;
}

.bgBlue table td {
  border-color: rgba(255, 255, 255, 0.3);
}

.blockWhite {
  background: #fff;
  color: #012140;
  padding: 40px 80px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.blockWhite ul li:before {
  background-color: #0a4475;
}

.borderRadius20 {
  border-radius: 20px;
}

h2,
.h2 {
  font-size: 44px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 30px 0;
  letter-spacing: -0.03em;
}

.formLine {
  margin: 0 0 25px 0;
}
.formLine small {
  display: block;
  margin: 0 0 5px 0;
}

@media (max-width: 1279px) {
  .formLine {
    margin: 0 0 15px 0;
  }
  .blockWhite {
    padding: 40px 60px;
  }
  input[type='email'],
  input[type='password'] {
    font-size: inherit;
  }
  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  input[type='email'],
  input[type='password'] {
    height: 36px;
    padding: 6px 10px 6px 10px;
  }
}
@media (max-width: 767px) {
  .formLine {
    margin: 0 0 15px 0;
  }
}

@media (max-width: 1023px) {
  input[type='checkbox'] + label:before,
  input[type='radio'] + label:before {
    top: 1px;
  }
}

.small,
small {
  font-size: 16px;
  margin: 0px 0 22px 0;
}
.small-2 {
  font-size: 14px;
}

.small input[type='checkbox'] + label:after {
  top: 0px;
}

.btn {
  font-family: 'Manrope', sans-serif;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.label {
  position: relative;
  padding: 0 0 0 30px !important;
  margin-right: 0px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

input[type='email'],
input[type='password'] {
  font-family: 'Manrope', sans-serif;
  font-size: inherit;
  line-height: 1;
  height: 39px;
  padding: 6px 10px 6px 10px;
  color: #012140;
  background-color: #fff;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  border-radius: 5px;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

input[type='email']:focus,
input[type='password']::focus,
input[type='email']:active,
input[type='password']:active,
select:active,
textarea:active {
  border-color: #012140;
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  user-select: none;
}

input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 1px solid #cdd1da;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 0;
}

input[type='checkbox']:checked + label:before {
  background-color: #b60e27;
  border-color: #b60e27;
}

input[type='checkbox'] + label:after {
  content: ' ';
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IGZpbGw9IiNCNjBFMjciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIvPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTEyLjYzLDUuNzA5YzAuMDc3LTAuMDg4LDAuMTM2LTAuMTkxLDAuMTczLTAuMzAzYzAuMDM3LTAuMTEyLDAuMDUxLTAuMjI5LDAuMDQyLTAuMzQ2DQoJcy0wLjA0Mi0wLjIzMS0wLjA5Ni0wLjMzNmMtMC4wNTQtMC4xMDQtMC4xMjgtMC4xOTctMC4yMTktMC4yNzJjLTAuMDktMC4wNzUtMC4xOTUtMC4xMzItMC4zMDctMC4xNjZzLTAuMjMtMC4wNDYtMC4zNDctMC4wMzQNCgljLTAuMTE3LDAuMDEyLTAuMjMsMC4wNDctMC4zMzMsMC4xMDRjLTAuMTAzLDAuMDU2LTAuMTk0LDAuMTMzLTAuMjY3LDAuMjI1TDcuNDg4LDkuMTI3bC0xLjk2LTEuOTYxDQoJYy0wLjE2Ni0wLjE2LTAuMzg5LTAuMjQ5LTAuNjItMC4yNDdDNC42NzcsNi45Miw0LjQ1Niw3LjAxMyw0LjI5Myw3LjE3NkM0LjEyOSw3LjM0LDQuMDM3LDcuNTYsNC4wMzUsNy43OTENCgljLTAuMDAyLDAuMjMxLDAuMDg3LDAuNDU0LDAuMjQ3LDAuNjJsMi42NDMsMi42NDNjMC4wODcsMC4wODcsMC4xOSwwLjE1NCwwLjMwNCwwLjE5OHMwLjIzNiwwLjA2NCwwLjM1OCwwLjA1OQ0KCWMwLjEyMi0wLjAwNiwwLjI0Mi0wLjAzNiwwLjM1Mi0wLjA5MWMwLjExLTAuMDU0LDAuMjA3LTAuMTMxLDAuMjg1LTAuMjI1TDEyLjYzLDUuNzA5eiIvPg0KPC9zdmc+DQo=');
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 2px;
  opacity: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
/* .small input[type=checkbox] + label:after,
  .small input[type=checkbox] + label:before
   {
      top: 0px;
  }
  
  .small input[type=checkbox] + label:before,
  .btn-small input[type=checkbox] + label:before{
      top: 0px;
  } */

input[type='checkbox']:checked + label:after {
  opacity: 1 !important;
}

a {
  text-decoration: none;
  color: #0a4475;
}
a:hover {
  color: #9a031a;
}

.colorWhite {
  color: #fff !important;
}

@media (max-width: 1279px) {
  .block {
    padding: 60px 0 60px 0;
  }

  .formLine {
    margin: 0 0 15px 0;
  }

  .blockWhite {
    padding: 40px 60px;
  }

  input[type='email'],
  input[type='password'] {
    font-size: inherit;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  input[type='email'],
  input[type='password'] {
    height: 36px;
    padding: 6px 10px 6px 10px;
  }

  .label {
    display: block;
    height: 20px;
    padding-top: 2px !important;
  }
}

@media (max-width: 1023px) {
  .blockWhite {
    padding: 30px 40px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .small,
  small {
    font-size: 14px;
  }

  form.form {
    margin-bottom: 1.5rem !important;
  }
}

@media (max-width: 767px) {
  .blockWhite {
    padding: 20px 20px;
  }

  .formLine {
    margin: 0 0 15px 0;
  }

  .btn {
    width: 100%;
  }
}
