.win {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 10000;
  display: none;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.win:before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.47);
}

.winContainer {
  text-align: center;
  padding: 0;
  margin-top: 0;
  display: table-cell;
  width: 100vw;
  height: 100vh;
  vertical-align: middle;
  position: relative;
}

.winContent {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  position: relative;
  text-align: left;
  display: inline-block;
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.1));
  max-width: 700px;
  min-width: 320px;
  margin: 20px 20px;
}

.winContentFulltext {
  padding: 50px;
  overflow: auto;
}

.winContentFulltext img {
  width: 100%;
  height: auto;
}

.winContentFulltext h3,
.winContentFulltext .h3 {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 25px 0;
  letter-spacing: -0.03em;
}

.winContent:after {
  display: block;
  width: 100%;
  content: ' ';
  clear: both;
}

.winContent .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
  line-height: 25px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.6;
}

.close:hover {
  opacity: 1;
}

.reportDate {
  position: relative;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btnBlue:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnSmall {
  font-size: 16px;
  padding: 8px 15px;
  text-transform: none;
  margin: 0;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.btnWide {
  display: block;
  width: 100%;
}

.loadGallery input {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.loadGalleryImages {
  border-radius: 5px !important;
  background-color: #f5f9ff !important;
  border: 1px solid #bcc1c8 !important;
  margin: 0px 7px 5px 0px;
  display: inline-block;
  position: relative;
  width: 120px;
  height: 70px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.loadGalleryPhoto {
  display: flex;
  justify-content: center;
}

.loadGalleryImages .loadGalleryPhoto a {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loadGalleryImages .loadGalleryPhoto .del {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  opacity: 1;
  background: #fff;
  border-radius: 30px;
  width: 20px;
  height: 20px;
}

.loadGalleryImages .loadGalleryPhoto .del:before {
  content: '×';
}

.loadGalleryImages .loadGalleryPhoto.forDelete:before {
  content: ' ';
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
}

.loadGalleryImages .loadGalleryPhoto.forDelete:after {
  content: ' ';
  display: block;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  position: absolute;
  width: 20px;
  height: 20px;
  background: 'url(../img/icon-delete.svg)' center center no-repeat;
  background-size: contain;
}

.reportsImgContainer {
  position: relative;
  display: inline-block;
  margin: 10px;

  /* Или flex, в зависимости от вашего макета */
}

.reportsImg {
  width: 154px !important;
  height: auto !important;
}

.del {
  position: absolute;
  font-weight: 500;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  font-size: 30px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
}

.loadGalleryImages .loadGalleryPhoto.forDelete .del:before {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80% 80%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi4wMDcyIDE3Ljg3NzZDMjEuNjAzNiAxNy45NzA4IDIxLjIwMDkgMTcuNzE5MSAyMS4xMDc3IDE3LjMxNTVMMjAuMzc4MyAxNC4xNTZDMjAuMzQ3MiAxNC4wMjE0IDIwLjIxMyAxMy45Mzc2IDIwLjA3ODQgMTMuOTY4NkwxNi45MTg5IDE0LjY5OEMxNi41MTUzIDE0Ljc5MTIgMTYuMTEyNiAxNC41Mzk2IDE2LjAxOTQgMTQuMTM2QzE1LjkyNjIgMTMuNzMyNCAxNi4xNzc5IDEzLjMyOTcgMTYuNTgxNSAxMy4yMzY1TDE5Ljc0MSAxMi41MDcxQzIwLjY4MjcgMTIuMjg5NyAyMS42MjI0IDEyLjg3NjggMjEuODM5OCAxMy44MTg2TDIyLjU2OTIgMTYuOTc4MUMyMi42NjI0IDE3LjM4MTcgMjIuNDEwOCAxNy43ODQ0IDIyLjAwNzIgMTcuODc3NloiIGZpbGw9IiMwMDI1NTIiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi40OTk4IDE4Ljg5N0M4LjQ5NTc1IDE4Ljg5NyA1LjI0OTgyIDE1LjY1MSA1LjI0OTgyIDExLjY0N0M1LjI0OTgyIDcuNjQyOTEgOC40OTU3NSA0LjM5Njk3IDEyLjQ5OTggNC4zOTY5N0MxMi45MTQgNC4zOTY5NyAxMy4yNDk4IDQuMDYxMTkgMTMuMjQ5OCAzLjY0Njk3QzEzLjI0OTggMy4yMzI3NiAxMi45MTQgMi44OTY5NyAxMi40OTk4IDIuODk2OTdDNy42NjczMyAyLjg5Njk3IDMuNzQ5ODIgNi44MTQ0OCAzLjc0OTgyIDExLjY0N0MzLjc0OTgyIDE2LjQ3OTUgNy42NjczMyAyMC4zOTcgMTIuNDk5OCAyMC4zOTdDMTYuMjYwMiAyMC4zOTcgMTkuNDY0NyAxOC4wMjUzIDIwLjcwMjcgMTQuNjk4N0MyMC44NDcyIDE0LjMxMDUgMjAuNjQ5NiAxMy44Nzg3IDIwLjI2MTQgMTMuNzM0MkMxOS44NzMyIDEzLjU4OTcgMTkuNDQxNCAxMy43ODczIDE5LjI5NjkgMTQuMTc1NUMxOC4yNzAzIDE2LjkzMzkgMTUuNjEzNCAxOC44OTcgMTIuNDk5OCAxOC44OTdaIiBmaWxsPSIjMDAyNTUyIi8+Cjwvc3ZnPgo=);
}

.small,
small {
  font-size: 16px !important;
}

.btn .icon {
  width: 18px;
  height: 18px;
  line-height: 1;
  margin-right: 8px;
  margin-top: -2px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.iconCheckWhite {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NiA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi45OTkzIDEuOTE2NzVDMTEuMzU1NiAxLjkxNjc1IDEuOTE2MDIgMTEuMzU2MyAxLjkxNjAyIDIzLjAwMDFDMS45MTYwMiAzNC42NDM4IDExLjM1NTYgNDQuMDgzNCAyMi45OTkzIDQ0LjA4MzRDMzQuNjQzMSA0NC4wODM0IDQ0LjA4MjcgMzQuNjQzOCA0NC4wODI3IDIzLjAwMDFDNDQuMDgyNyAxMS4zNTYzIDM0LjY0MzEgMS45MTY3NSAyMi45OTkzIDEuOTE2NzVaTTMyLjEzOCAxOS40MzUxQzMyLjMwNjMgMTkuMjQyNyAzMi40MzQ0IDE5LjAxODcgMzIuNTE0OCAxOC43NzYxQzMyLjU5NTIgMTguNTMzNSAzMi42MjYzIDE4LjI3NzIgMzIuNjA2MiAxOC4wMjI1QzMyLjU4NjEgMTcuNzY3NyAzMi41MTUzIDE3LjUxOTUgMzIuMzk3OSAxNy4yOTI1QzMyLjI4MDQgMTcuMDY1NSAzMi4xMTg4IDE2Ljg2NDMgMzEuOTIyNSAxNi43MDA3QzMxLjcyNjEgMTYuNTM3IDMxLjQ5OTEgMTYuNDE0MyAzMS4yNTQ2IDE2LjMzOThDMzEuMDEwMiAxNi4yNjUyIDMwLjc1MzMgMTYuMjQwMyAzMC40OTkgMTYuMjY2NUMzMC4yNDQ4IDE2LjI5MjcgMjkuOTk4NCAxNi4zNjk1IDI5Ljc3NDMgMTYuNDkyM0MyOS41NTAyIDE2LjYxNTEgMjkuMzUyOSAxNi43ODE1IDI5LjE5NCAxNi45ODE3TDIwLjk1MjMgMjYuODY5OEwxNi42ODc4IDIyLjYwMzNDMTYuMzI2MyAyMi4yNTQyIDE1Ljg0MjEgMjIuMDYxIDE1LjMzOTYgMjIuMDY1NEMxNC44MzcgMjIuMDY5NyAxNC4zNTYzIDIyLjI3MTMgMTQuMDAwOSAyMi42MjY3QzEzLjY0NTYgMjIuOTgyIDEzLjQ0NCAyMy40NjI4IDEzLjQzOTYgMjMuOTY1M0MxMy40MzUzIDI0LjQ2NzkgMTMuNjI4NSAyNC45NTIgMTMuOTc3NiAyNS4zMTM1TDE5LjcyNzYgMzEuMDYzNUMxOS45MTU5IDMxLjI1MTcgMjAuMTQxNCAzMS4zOTg2IDIwLjM4OTYgMzEuNDk0OUMyMC42Mzc5IDMxLjU5MTEgMjAuOTAzNSAzMS42MzQ2IDIxLjE2OTQgMzEuNjIyNUMyMS40MzU0IDMxLjYxMDQgMjEuNjk2IDMxLjU0MyAyMS45MzQ1IDMxLjQyNDdDMjIuMTczIDMxLjMwNjMgMjIuMzg0MiAzMS4xMzk2IDIyLjU1NDcgMzAuOTM1MUwzMi4xMzggMTkuNDM1MVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
}

.date {
  color: #012140 !important;
}

.title {
  margin-top: -20px;
  margin-bottom: 20px;
}

@media (max-width: 1279px) {
  .winContent {
    max-width: 600px;
  }

  .winContentFulltext {
    padding: 30px;
  }
}

@media (max-width: 1023px) {
  h4,
  .h4 {
    font-size: 22px;
    margin: 0 0 20px 0;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}

@media (max-width: 1023px) {
  body {
    font-size: 16px;
  }

  .font-big {
    font-size: 20px;
  }

  .small,
  small {
    font-size: 14px;
  }

  h3,
  .h3 {
    font-size: 26px;
    margin: 0 0 25px 0;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btn-small {
    font-size: 14px !important;
    padding: 8px 13px !important;
  }
  .loadGalleryPhoto {
    flex-wrap: wrap;
  }
  .reportsImg {
    width: 130px !important;
  }
}

@media (max-width: 767px) {
  .winContent {
    margin: 10px 15px;
  }

  .winContentFulltext {
    padding: 20px 20px 30px 20px;
  }

  .reportsImg {
    width: 120px !important;
  }
}

@media (max-width: 575px) {
  .btn {
    width: 100%;
  }
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:disabled:before {
  opacity: 0.5;
  content: none;
  cursor: not-allowed;
}

.btn:disabled .icon {
  display: none;
  cursor: not-allowed;
}

.btn:disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.timeZone {
  display: block;
  position: absolute;
}

.errorText {
  color: #9a031a;
  font-size: 12px !important;
}
