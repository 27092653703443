.accountMobilePanel {
  display: none;
}

.menuMobile {
  display: none;
  position: relative;
  user-select: none;
  z-index: 10;
  width: 28px;
  height: 28px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzBweCIgaGVpZ2h0PSIyNC4xODhweCIgdmlld0JveD0iMCAtNS41NjMgMzAgMjQuMTg4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgLTUuNTYzIDMwIDI0LjE4OCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNMzAtMy4xMjVjMCwxLjI5NC0xLjA0OSwyLjM0NC0yLjM0NCwyLjM0NEgyLjM0NEMxLjA0OS0wLjc4MSwwLTEuODMxLDAtMy4xMjVsMCwwYzAtMS4yOTQsMS4wNDktMi4zNDQsMi4zNDQtMi4zNDRoMjUuMzEzDQoJQzI4Ljk1MS01LjQ2OSwzMC00LjQxOSwzMC0zLjEyNUwzMC0zLjEyNXoiLz4NCjxwYXRoIGQ9Ik0zMCwxNi4xODhjMCwxLjI5NC0xLjA0OSwyLjM0NC0yLjM0NCwyLjM0NEgyLjM0NEMxLjA0OSwxOC41MzEsMCwxNy40ODIsMCwxNi4xODhsMCwwYzAtMS4yOTQsMS4wNDktMi4zNDQsMi4zNDQtMi4zNDQNCgloMjUuMzEzQzI4Ljk1MSwxMy44NDQsMzAsMTQuODkzLDMwLDE2LjE4OEwzMCwxNi4xODh6Ii8+DQo8cGF0aCBkPSJNMzAsNi43MTljMCwxLjI5NC0xLjA0OSwyLjM0NC0yLjM0NCwyLjM0NEgyLjM0NEMxLjA0OSw5LjA2MywwLDguMDEzLDAsNi43MTlsMCwwYzAtMS4yOTQsMS4wNDktMi4zNDQsMi4zNDQtMi4zNDRoMjUuMzEzDQoJQzI4Ljk1MSw0LjM3NSwzMCw1LjQyNCwzMCw2LjcxOUwzMCw2LjcxOXoiLz4NCjwvc3ZnPg0K');
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.menuMobile.opened {
  background: none;
  color: #222;
  margin: 0 5px 0 0;
}
.menuMobile.opened:before {
  content: '\00D7';
  display: block;
  font-weight: 300;
  text-align: center;
  font-size: 56px;
  line-height: 36px;
}

.headerAccount {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.logoMobile {
  display: none;
}

.headerAccount .top-menu-account {
  padding: 17px 40px 19px 200px;
}

.headerAccount .top-menu-account a {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px;
  margin: 0 10px 0 0;
}

.topMenuAccount a.active {
  color: #b60e27;
}

.avatar {
  float: right;
  text-align: right;
  white-space: nowrap;
  margin: 10px 50px 10px 0;
}

.avatar {
  float: right;
  text-align: right;
  white-space: nowrap;
  margin: 10px 50px 10px 0;
}
.avatarLink {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px 0 0;
  color: #012140;
  font-weight: 800;
}

.avatarLink:hover {
  color: #b60e27;
}

.avatarImg {
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin: 0 5px 0 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.name {
  display: inline-block;
  vertical-align: middle;
}

.accountMobileMenu {
  display: none;
}
@media (max-width: 1023px) {
  .accountMobilePanel {
    display: block;
    background: #0a4475;
    color: #fff;
    padding: 10px 0;
    margin: 0;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 45px;
  }

  .logoMobile {
    display: inline-block;
    margin: 0 0 0 15px;
  }
  .avatarImgMobile {
    height: 25px;
  }
  .menuMobile {
    position: absolute;
    top: 7px;
    right: 15px;
    z-index: 10;
    width: 28px;
    height: 28px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzBweCIgaGVpZ2h0PSIyNC4zMzNweCIgdmlld0JveD0iMCAyLjgzMyAzMCAyNC4zMzMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAyLjgzMyAzMCAyNC4zMzMiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTMwLDUuMzQ0YzAsMS4yOTQtMS4wNDksMi4zNDQtMi4zNDQsMi4zNDRIMi4zNDRDMS4wNDksNy42ODgsMCw2LjYzOCwwLDUuMzQ0bDAsMEMwLDQuMDUsMS4wNDksMywyLjM0NCwzDQoJaDI1LjMxM0MyOC45NTEsMywzMCw0LjA1LDMwLDUuMzQ0TDMwLDUuMzQ0eiIvPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTMwLDI0LjY1N2MwLDEuMjk0LTEuMDQ5LDIuMzQ0LTIuMzQ0LDIuMzQ0SDIuMzQ0QzEuMDQ5LDI3LDAsMjUuOTUxLDAsMjQuNjU3bDAsMA0KCWMwLTEuMjk0LDEuMDQ5LTIuMzQ0LDIuMzQ0LTIuMzQ0aDI1LjMxM0MyOC45NTEsMjIuMzEzLDMwLDIzLjM2MiwzMCwyNC42NTdMMzAsMjQuNjU3eiIvPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTMwLDE1LjE4OGMwLDEuMjk0LTEuMDQ5LDIuMzQ0LTIuMzQ0LDIuMzQ0SDIuMzQ0QzEuMDQ5LDE3LjUzMiwwLDE2LjQ4MiwwLDE1LjE4OGwwLDANCgljMC0xLjI5NCwxLjA0OS0yLjM0NCwyLjM0NC0yLjM0NGgyNS4zMTNDMjguOTUxLDEyLjg0NCwzMCwxMy44OTMsMzAsMTUuMTg4TDMwLDE1LjE4OHoiLz4NCjwvc3ZnPg0K');
    cursor: pointer;
  }
  .accountMobilePanel .menuMobile.opened {
    background: none;
    color: #fff;
    margin: 0 0px 0 0;
  }
  .accountMobilePanel .menuMobile.opened:before {
    content: '\00D7';
    display: block;
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-size: 60px;
    line-height: 25px;
    margin: 3px 0 0 0;
  }

  .menuMobile {
    display: block;
    float: right;
  }

  .headerAccount {
    display: block;
    position: fixed;
    width: 100%;
    height: calc(100% - 45px);
    top: 45px;
    /* left: auto; */
    right: -101%;
    overflow: auto;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    box-shadow: -8px 0px 20px rgba(0, 0, 0, 0.05);
  }
  .headerAccount.opened {
    right: 0%;
  }

  .avatar {
    position: relative;
    float: none;
    text-align: center;
    white-space: normal;
    margin: 20px 15px 20px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;
  }
  .iconLogout {
    position: absolute;
    bottom: 23px;
    right: 0px;
  }

  .avatarLink {
    display: block;
    margin: 0 auto 0 auto;
  }

  .avatarImg {
    display: block;
    vertical-align: middle;
    width: 70px;
    height: 70px;
    border-radius: 150px;
    margin: 0 auto 15px auto;
    object-fit: cover;
  }

  .accountMobileMenu {
    display: block;
    padding: 0 0px 20px 0px;
    margin: 0 15px 20px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .navLink {
    display: block;
    padding: 8px 0;
    margin: 8px 0;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  .accountMobileMenu a.active {
    color: #b60e27;
  }

  .top-menu-account {
    padding: 0 0 20px 0;
    margin: 0;
    text-align: center;
  }
  .headerAccount .top-menu-account a {
    display: block;
    text-align: center;
    padding: 10px;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .avatarLink .avatarImg {
    width: 35px;
    height: 35px;
    margin: 0 6px 0 0;
    object-fit: cover;
  }
}

.iconLogout {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  opacity: 0.4;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjBweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjAgMTgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDE4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwMTIxNDAiIGQ9Ik0xNCwxMFY4SDVWNUwwLDlsNSw0di0zSDE0eiIvPg0KPHBhdGggZmlsbD0iIzAxMjE0MCIgZD0iTTE4LDBIOUM3Ljg5NywwLDcsMC44OTcsNywydjRoMlYyaDl2MTRIOXYtNEg3djRjMCwxLjEwMywwLjg5NywyLDIsMmg5YzEuMTAzLDAsMi0wLjg5NywyLTJWMg0KCUMyMCwwLjg5NywxOS4xMDMsMCwxOCwweiIvPg0KPC9zdmc+DQo=');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.iconLogout:hover {
  opacity: 1;
}

@media (max-width: 1023px) {
  .accountMobileMenu {
    display: block;
    padding: 0 0px 20px 0px;
    margin: 0 15px 20px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .mobileLink {
    display: block;
    padding: 8px 0;
    margin: 8px 0;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  .mobileLink.active {
    color: #b60e27;
  }
}
