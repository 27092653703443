.footer {
  color: #fff;
  padding: 30px 0;
  position: relative;
  height: 10vh;
}

.bgDarkBlue {
  background-color: #012140;
  color: #fff;
}

.footer .container {
  position: relative;
}

.footer .copyright {
  text-align: center;

  opacity: 0.4;
}

.footer a:not(.btn) {
  color: #fff;
}

.small2 {
  font-size: 14px;
}

@media (max-width: 1023px) {
  .footer {
    padding: 30px 0 30px 0;
  }
  .footer .copyright {
    text-align: center;
    margin: 0px 40px 0 40px;
  }
  .small2 {
    font-size: 14px;
  }
}

.linkTop {
  position: absolute;
  bottom: 15px;
  right: 15px;
  transform: rotate(90deg);
  font-size: 50px;
  font-weight: 800;
  opacity: 0.6;
}
.linkTop span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  margin: -4px 0px 0px -12px;
  vertical-align: middle;
  transform: rotate(-90deg);
}

.linkTop:hover {
  opacity: 1;
}

/* .linkto{
    offset-anchor: ;
} */
